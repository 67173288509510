import { Box } from '@chakra-ui/react';
import React from 'react';

import { WLText } from '../../design-library';
import PageContext from '../../templates/context/PageContext';

interface PageIdentifierProps {
  webNumber: string | undefined;
  secondaryWebNumber?: string | undefined;
  lastUpdated: string | undefined;
}

const PageIdentifier: React.FC<PageIdentifierProps> = ({
  webNumber,
  secondaryWebNumber,
  lastUpdated,
}) => {
  const path = React.useContext(PageContext);

  if (!webNumber && !lastUpdated) {
    return null;
  }

  const aDate = lastUpdated?.split('/');
  let date = lastUpdated;

  if (aDate?.length === 3) {
    const tempYear = aDate[2].toString();
    let year = new Date().getFullYear().toString();

    if (tempYear.length === 4) {
      year = tempYear.substring(2);
    } else {
      year = tempYear;
    }

    date = `${aDate[0]}/${year}`;
  }

  return (
    <Box mb={'80px'} fontSize={12} fontWeight={700} color="gray.600">
      {webNumber && (
        <WLText fontSize={12} fontWeight={700} color="gray.600">
          {[webNumber, date].filter((val) => !!val).join(' R-')}
        </WLText>
      )}
      {secondaryWebNumber && (
        <WLText fontSize={12} fontWeight={700} color="gray.600">
          {secondaryWebNumber}
        </WLText>
      )}
    </Box>
  );
};

export default PageIdentifier;
