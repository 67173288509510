const Frame = {
  parts: ['marquee', 'frame', 'header', 'content', 'disclosures'],
  baseStyle: ({ colorScheme }: any) => ({
    marquee: {
      width: 'full',
      height: '200px',
      background: 'rgba(225,238,251,.5);',
    },
    frame: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: { base: '332px', tablet: '442px', lg: '552px' },
      height: 'auto',
      margin: '0 auto',
      marginTop: { base: '12px', lg: '-80px' },
      padding: 0,
      borderRadius: '16px',
      backgroundColor: '#E1EEFB',
      filter: `drop-shadow(0 0 32px rgba(0, 0, 0, .05));`,
      transition: `all .3s ease;`,
    },
    content: {
      width: 'full',
      textAlign: 'center',
      color: `wl-blue.navy`,
      padding: '32px 0 40px 0',
      borderRight: '1px solid #E7E6E5',
      borderBottom: '1px solid #E7E6E5',
      borderLeft: '1px solid #E7E6E5',
      borderRadius: '0 0 16px 16px',
      backgroundColor: 'white',
      h2: {
        margin: '0',
        fontSize: { base: '32px', tablet: '48px' },
        lineHeight: { base: '36px', tablet: '52px' },
      },
      p: {
        mb: { base: '4px', tablet: '6px' },
        lineHeight: { base: '22px', tablet: '28px' },
      },
      [`p, b`]: {
        fontFamily: 'heading',
        fontSize: { base: '16px', tablet: '24px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    header: {
      position: 'relative',
      width: { base: 'full', tablet: '442px', lg: '550px' },
      height: { base: 'auto', tablet: 'full' },
      padding: { base: '24px 0px' },
      transition: 'all .3s ease',
      textAlign: 'center',
      h2: {
        padding: 0,
        margin: 0,
        fontSize: { base: '32px', tablet: '48px' },
        transition: 'all .3s ease',
        lineHeight: { base: '36px', tablet: '52px' },
        sup: {
          top: '-6px',
          verticalAlign: 'super',
          position: 'relative',
          transition: 'all .3s ease',
        },
      },
      p: {
        fontFamily: 'heading',
        fontWeight: 'bold',
        color: '#036',
        letterSpacing: '-.025em',
        wordSpacing: '0.075em',
        lineHeight: { tablet: '28px' },
        margin: '0',
        fontSize: { base: '16px', tablet: '24px' },
        transition: 'all .3s ease',
      },
    },
    disclosures: {
      margin: '0 0 0 0',
      marginTop: '16px',
      padding: '0 19px',
      p: {
        fontFamily: 'Inter',
        fontSize: '12px',
        lineHeight: '18px',
        textAlign: 'center',
        color: 'gray.70',
      },
    },
  }),
  sizes: {},
  variants: {
    horizontal: {
      frame: {
        flexDirection: { base: 'column', tablet: 'row' },
        width: 'full',
        maxWidth: '1128px',
        overflow: 'hidden',
        background: '#E1EEFB',
        border: '1px solid #E7E6E5',
        borderRadius: '16px',
        filter: 'drop-shadow(0 8px 12px rgba(0, 0, 0, .025));',
        alignItems: 'center',
      },
      header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: { base: 'full', tablet: '50%', lg: '50%' },
        height: { tablet: 'full' },
      },
      content: {
        width: { base: 'full', tablet: '50%', lg: '50%' },
        height: 'full',
        padding: {
          base: '24px 0px',
          tablet: '40px',
        },
        borderRight: '1px solid #E7E6E5',
        borderBottom: '1px solid #E7E6E5',
        borderLeft: '1px solid #E7E6E5',
        borderRadius: { base: '0 0 16px 16px', tablet: '0 16px 16px 0px' },
        overflow: 'hidden',

        p: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        ul: {
          [`li [class*="decorator"]`]: {
            height: { tablet: '32px !important' },
            width: { tablet: '2px !important' },
            margin: { tablet: '0 16px !important' },
          },
          padding: 0,
          li: {
            flexDirection: { base: 'column', tablet: 'row !important' },
            margin: { tablet: '0 0 16px 0 !important' },
            span: {
              _first: {
                textAlign: { base: 'unset', tablet: 'right' },
                flexBasis: { base: 'unset', tablet: '90px' },
              },
            },
          },
        },
      },
      disclosures: {},
    },
  },
  defaultProps: {},
};

export default Frame;
